// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

import { CaptureConsole, ExtraErrorData } from '@sentry/integrations'

import { ENVIRONMENT } from './constants/Environment'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://afe3363128e84b5b988e78c25f8889bd@o465486.ingest.sentry.io/6007788',
  environment: ENVIRONMENT,
  normalizeDepth: 10,
  maxBreadcrumbs: 10,
  integrations: [
    new ExtraErrorData({
      depth: 10,
    }),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
