import { baseRemoteAPIURL } from './URLs'

/** ENV variable ENVIRONMENT value, can be either development, production or localhost  */
export enum EnvironmentValue {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  LOCALHOST = 'localhost'
}

const EnvBasedOnAPIURL = (
  baseRemoteAPIURL === 'https://api.dev.onoffice.bkbn.com'
    ? EnvironmentValue.DEVELOPMENT
    : baseRemoteAPIURL === 'https://api.onoffice.bkbn.com'
      ? EnvironmentValue.PRODUCTION
      : EnvironmentValue.LOCALHOST
)

/** String containing environment value, can be either development, production or localhost */
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || EnvBasedOnAPIURL || EnvironmentValue.LOCALHOST