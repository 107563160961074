import 'styles/global.sass'

import { AppProps } from 'next/app'
import smoothscroll from 'smoothscroll-polyfill'

if (process.browser) {
  smoothscroll.polyfill()
}

function App({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />
}

export default App